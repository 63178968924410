@use '@/shared/ui/button/adaptive' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.clear-btn {
  @include buttonSizes('34', '44', '44', '42', '46', '46');

  padding: 8px 20px;

  @include min-428-break {
    padding: 11.5px 20px;
  }

  @include min-1024-break {
    padding: 10.5px 20px;
  }

  @include min-1440-break {
    padding: 12.5px 20px;
    border-radius: var(--cornerradius10);
  }
}

.mobile {
  @include singleFixedSize(34px);

  @include min-428-break {
    @include singleFixedSize(44px);
  }
}
