@use '@button' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.empty {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
