@use '@button' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.checkbox {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  gap: 5px;

  .agreement-checkbox {
    gap: 10px;
    span[role='checkbox'] {
      width: 18px;
      height: 18px;
    }

    &.warn,
    &:hover {
      span[role='checkbox'] {
        border: 2px solid var(--cst-checkbox-outline-warning);
      }

      input[type='checkbox']:checked + span[role='checkbox'] {
        border-color: transparent;
      }
    }
  }

  .link {
    color: var(--cst-authorization-quaternarytext) !important;
    cursor: pointer;
  }

  .text-regular {
    @include typography-s;
    @include weight-medium;
    color: var(--cst-hyperlink-tittle-default);

    @include min-1440-break {
      @include typography-base;
    }
  }
}
