@use '@button' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.purchase {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 18px;
  max-height: max-content;
  max-width: 390px;
  min-width: 390px;
  width: 100%;
  box-shadow: inset 0 0 0 2px var(--cst-purchasecheckout-outline);
  padding: 14px;
  border-radius: 16px;

  .payment-methods-wrapper {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  @include min-1440-break {
    gap: 24px;
    max-width: 568px;
  }

  .action-btn {
    @include buttonSizes('38', '46', '54', '42', '54', '54');

    span[id='button-span-text'] {
      @include typography-s;
      @include weight-semi-bold;

      @include min-1440-break {
        @include typography-m;
      }
    }

    span[id='btn-icon'],
    svg {
      @include singleFixedSize(18px);

      @include min-1440-break {
        @include singleFixedSize(22px);
      }
    }
  }

  .action-btn-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
