@use '@button' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.total-sum {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.skeleton {
  width: 100%;
  height: 175px;

  @include min-1440-break {
    height: 163px;
  }
}
