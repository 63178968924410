@use '@button' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.payment-methods {
  display: flex;
  width: 100%;
  gap: 6px;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 100%;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}
